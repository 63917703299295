import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class LanguageService {

  private static PREFERRED_LANGUAGE_KEY = "listx_language";
  public static SUPPORTED_LOCALES = ["en-US", "et"];

  constructor(
    private cookieService: CookieService
    ) {
  }
  
  getPreferredLanguages(): string[] {
    const selectedLanguage = localStorage.getItem(LanguageService.PREFERRED_LANGUAGE_KEY);
    
    if (selectedLanguage) {
      return [selectedLanguage];
    }
    
    return [...this.getPreferredLanguagesFromBrowser()];
  }
  
  storePreferredLanguage(language: string) {
    
    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 2);
    
    this.cookieService.set(LanguageService.PREFERRED_LANGUAGE_KEY, language, expires, '/');    
  }
 
  getPreferredLanguagesFromBrowser(): readonly string[] {
    return navigator.languages;
  } 
}